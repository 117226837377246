export default (value) => {
  const isNumberOrFloat = !isNaN(value) && typeof value === 'number'

  if (!isNumberOrFloat) {
    return value
  }

  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(value)
}
